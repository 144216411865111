.cleopatra {
  background-color: #000;
  height: 100vh;
  padding: 30px 0px;
}

.closeButton svg {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 1.7rem;
  border-radius: 4px;
  background-color: #f1f1f1;
  color: #314059;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease all;
  z-index: 1;
}

.closeButton svg:hover {
  background-color: #e32525;
  color: white;
}
