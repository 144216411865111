.footer {
  background-color: #fff;
  padding: 10px 0px 0px;
  border-top: 1px solid #82c8bd;
  margin-top: 50px;
  direction: ltr;
}

/* .logoConainer {
  display: flex;
  align-items: center;
} */

.logoConainer img {
  width: 14%;
  margin-right: 20px;
}

.logoConainer a:last-child img {
  width: 20%;
  margin-top: 10px;
}

.colLinks {
  display: flex;
  align-items: center;
  justify-content: center;
}

.colMedia {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.links a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #82c8bd;
  /* margin: 0px 15px; */
  padding: 0px 10px;
}

.links {
  display: flex;
}

.links a:hover {
  color: #51b6a7;
}

.media {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.media img {
  width: 47px;
  margin-left: 18px;
}

.footerCopyright {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #48ae9e;
  margin-top: 15px;
}

.footerCopyright p {
  margin: 0;
  padding: 0;
  margin: 10px 0px;
  text-align: center;
  font-weight: 500;
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerCopyright img {
  width: 80px;
  height: 30px;
  margin-left: 10px;
}

.footerCopyright a {
  margin: 0 4px;
  color: #fff !important;
}

/* ********************************media********************************** */
@media (max-width: 768px) {
  .logoConainer {
    flex-direction: column;
  }

  .logoConainer img {
    width: 70% !important;
    margin-bottom: 10px;
  }

  .logoConainer img:last-child {
    width: 75% !important;
  }

  .links {
    display: flex;
    flex-direction: column;
  }

  .media {
    flex-direction: column;
  }

  .media img {
    width: 35px;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .footerCopyright {
    flex-direction: column;
    margin-top: 20px;
  }

  .colLinks {
    display: flex;
    /* align-items: flex-start; */
    justify-content: center;
  }

  .colMedia {
    justify-content: center;
  }

  .links a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    color: #51b6a7;
    width: 100%;
    margin-bottom: 20px;
  }
}
