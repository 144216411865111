.bg {
  padding: 35px;
  background-color: #82c8bd;
  border-radius: 70px 70px 0px 0px;
  margin-top: -12px;
  position: relative;
}

.header {
  background-image: url("../../../assets/imgs/Dental cabinet bg.png");
  height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.header h1 {
  color: #fff;
  text-align: center;
  font-weight: bold;
  width: 60%;
  font-size: 50px;
}

.header p {
  color: #fff;
  font-size: 33px;
  font-weight: bold;
  text-align: center;
  padding: 0;
  margin: 30px 0px 0px 0px;
}

.imgContainer img {
  position: absolute;
  bottom: -15%;
  left: 50%;
  transform: translate(-50%);
  z-index: 2 !important;
  background: #fff;
  width: 40%;
  border-radius: 50px;
}

/* **************************** START MEDIA QUERY ******************************* */
@media (max-width: 768px) {
  .bg {
    border-radius: 0;
    padding: 5px;
  }

  .header {
    text-align: center;
    border-radius: 0;
  }

  .header h1 {
    font-size: 28px;
    margin-bottom: 0px;
    width: 100%;
  }

  .header p {
    font-size: 17px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .imgContainer {
    width: 90%;
    bottom: -10%;
  }

  .imgContainer img {
    background: #fff;
    width: 90%;
    border-radius: 50px;
  }
}
/* **************************** END MEDIA QUERY ******************************* */
