.navbar {
  padding: 15px 38px;
  transition: 0.3s;
  z-index: 9999 !important;
}

.navWithoutBackground {
  background-color: transparent !important;
}

.navBackground {
  background-color: #0e1913 !important;
}

.navBackground.navbar {
  background-color: #82c8bd !important;
  /* background-color: #2b3336f7 !important; */
  transition: 0.3s;
  box-shadow: 0px -4px 25px 1px #2b3336f7;
}

.navBackground.navbar {
  padding: 5px 38px;
  z-index: 9999 !important;
}

.header {
  margin-bottom: 67px !important;
}

.logo {
  height: 60px;
  transition: 0.5s;
}

.navBackground .logo {
  height: 50px;
}

.nav {
  margin-right: 20px;
  width: 100%;
  justify-content: flex-end;
  z-index: 9999 !important;
}

.nav a {
  margin: 0px 12px;
  color: #3ca998 !important;
  font-weight: 600;
  font-size: 17px;
}

.navBackground.navbar .nav a {
  color: #fff !important;
}

.navBackground.navbar .nav a:hover {
  color: #f1f1f1 !important;
}

.nav a:hover {
  color: #82c8bd !important;
}

.button1 {
  background-color: #7965ab;
  padding: 0px !important;
  color: #fff !important;
  text-align: center;
  font-size: 14px;
  width: 12%;
  padding: 5px 0px !important;
  border-radius: 15px;
  border: 1px solid rebeccapurple;
  letter-spacing: 0.2px;
  font-weight: 600;
  transition: 0.3s;
}

.button1:hover {
  background: none;
  color: #484848 !important;
  box-shadow: 5px 5px 10px 0px #e2e2e2;
}

.navBackground .button1 {
  background: #7965ab;
  color: #fff;
  box-shadow: none;
}

.nav button {
  border: none;
  background: none;
  color: #3ca998;
  font-weight: 600;
}

.navBackground.navbar .nav button {
  color: #fff;
}
.navBackground.navbar .nav button:hover {
  color: #f1f1f1;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 20px 10px;
    background-color: #82c8bd !important;
  }

  .navbarToggle {
    border: none;
    background-color: #444 !important;
    padding: 4px 9px !important;
  }

  .navbarToggle:focus {
    box-shadow: none !important;
  }

  .nav {
    margin-left: 0px;
  }

  .nav a:first-child {
    margin-top: 50px !important;
  }

  .nav a,
  button {
    margin: 0px !important;
    margin-bottom: 32px !important;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd !important;
    border-radius: 5px;
    color: #fff !important;
  }

  button {
    margin-bottom: 0 !important;
  }

  .navBackground.navbar {
    padding: 10px 20px !important;
    box-shadow: none;
  }

  .navDropDown {
    margin: 0 !important;
  }
}

.navDropDownItem {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
  padding: 10px !important;
  color: #3ca998 !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 5px;
}

.navDropDownItem:hover {
  background: #3ca998 !important;
}