h2 {
  font-size: 50px !important;
  text-align: center;
  font-weight: bold !important;
  margin-bottom: 50px !important;
  color: #f1f1f1;
}

.slider {
  width: 100%;
  /* height: 400px; */
  outline: none;
}

.slide,
.slide img {
  width: 100%;
  height: 500px;
  border-radius: 20px;
  outline: none;
  object-fit: contain;
}

.slide {
  opacity: 0.5;
  transform: scale(0.7);
  transition: 0.5s;
  filter: blur(5px);
}

.slide-active {
  filter: blur(0);
  opacity: 1;
  transform: scale(1);
  overflow: hidden;
}

.slide-active img {
  transform: scale(1);
  transition: 3s;
}

@media screen and (max-width: 769px) {
  h2 {
    margin-bottom: 0 !important;
    /* font-size: 30px !important; */
  }

  .slide-active {
    transform: scale(1.2);
    transition: 2s;
  }

  .slick-track {
    padding: 100px 0px 50px;
  }

  .slide,
  .slide img {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    outline: none;
  }
}

/* .slick-slider .slick-track, .slick-slider .slick-list { direction: ltr; } */