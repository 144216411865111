.bg {
  padding: 35px;
  background-color: #82c8bd;
  border-radius: 70px;
  margin-top: -12px;
  position: relative;
}

.header {
  background-color: #fff;
  border-radius: 50px;
  padding: 0px 50px 20px;
}

.header h1 {
  text-align: center;
  color: #50b4a4;
  text-shadow: 5px 5px 9px #ddd;
  padding: 5px 0px 20px;
}

.clientContent {
  background-color: #82c8bd;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  direction: ltr;
}

.clientContent h6 {
  margin: 0;
  padding: 0;
  color: #fff;
  text-shadow: 8px 8px 9px #666;
  font-size: 14.9px;
}

/* ********************************media********************************** */
@media (max-width: 768px) {
  .bg {
    padding: 0px;
    margin-top: 0;
  }

  .header {
    border-radius: 0px;
    padding: 0px;
    margin-top: 90px;
  }

  .clientContent h6 {
    font-size: 17px;
  }
}
/* ********************************media********************************** */
