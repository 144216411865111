/* .slick-dots li {
  display: none !important;
}


.slick-arrow {
  display: none !important;
} */

.slick-dots li {
  display: none !important;
}

@media screen and (max-width: 769px) {
  .slick-arrow {
    display: none !important;
  }
}
