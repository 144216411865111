.row2 {
  justify-content: center;
  margin-top: 20px !important;
}

.clients {
  padding: 35px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(9.5px);
  -webkit-backdrop-filter: blur(9.5px);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(12.5px);
}

.clients {
  background-color: #82c8bd;
}

.clients h1 {
  text-align: center;
  font-weight: 500;
  color: #fff;
  margin-bottom: 40px;
}

.clientsConent {
  background: #f1f1f1;
  width: 100%;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
}

.imgContainer,
.imgContainer1 {
  background-color: #82c8bd;
  border-radius: 7px;
  box-shadow: 10px 10px 10px 0px rgb(137 137 137);
}

.imgContainer img,
.imgContainer1 img {
  width: 100%;
  height: 300px;
  border-radius: 7px;
  object-fit: cover;
}

/* ********************************media********************************** */
@media (max-width: 768px) {
  .clients {
    padding: 0px;
  }

  .clientsConent {
    background: none;
    width: 100%;
    margin: auto;
    padding: 0px;
    border-radius: 10px;
  }

  .imgContainer,
  .imgContainer1 {
    margin-bottom: 30px;
  }
}
/* ********************************media********************************** */
